import apiClient from './base';

interface Patient {
    nome: string;
    cognome: string;
    codice_fiscale: string;
    data_nascita: string;
}

interface Shape {
    id_shape: number;
    id_paziente: number;
    timestamp_creazione: string;
    json_risposta: string;
    nome: string;              // Aggiungi i campi mancanti qui
    cognome: string;           // Aggiungi i campi mancanti qui
    codice_fiscale: string;    // Aggiungi i campi mancanti qui
    data_nascita: string;      // Aggiungi i campi mancanti qui
}

interface ShapeDetailData {
    patient: Patient;
    latestShape: Shape;
    firstShape: Shape | null;
}

interface GetShapeDetailResponse {
    shapes: Shape[];
}

export const getShapeDetail = async (id_shape: number): Promise<ShapeDetailData> => {
    try {
        const response = await apiClient.post<GetShapeDetailResponse>('/shapes/fetch.php', {
            id_shape: id_shape,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        console.log('API response:', response.data);

        const latestShape = response.data.shapes[0];
        const patient = {
            nome: latestShape.nome,
            cognome: latestShape.cognome,
            codice_fiscale: latestShape.codice_fiscale,
            data_nascita: latestShape.data_nascita
        };

        return {
            patient: patient,
            latestShape: latestShape,
            firstShape: response.data.shapes.length > 1 ? response.data.shapes[response.data.shapes.length - 1] : null
        };
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Error fetching shape detail');
        } else {
            throw new Error('Error fetching shape detail');
        }
    }
};
