import React from "react";

interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {
    primaryColor?: string
}

const Logo = (props: LogoProps) => {
    const primaryColor = props.primaryColor ?? '#009bbc';
    const { secondaryColor, tertiaryColor } = calculateColorSwatch(primaryColor);

    return (
        <div {...props} className={['Logo', props.className].join(' ')}>
            <svg width="100%" height="100%" viewBox="0 0 284.7 83.13">
                <g>
                    <g>
                        <path fill={primaryColor} d="M0,50.56l16.82-1.64a8.56,8.56,0,0,0,2.91,4.44,8.46,8.46,0,0,0,5,1.34A8.21,8.21,0,0,0,30,53.19a3.39,3.39,0,0,0,1.45-2.8,3.2,3.2,0,0,0-1.91-2.93q-1.38-.74-7.29-1.81a73.92,73.92,0,0,1-12.26-3A12.87,12.87,0,0,1,4.23,38.1a12.22,12.22,0,0,1-2.36-7.38,12.38,12.38,0,0,1,2.54-7.8,15.15,15.15,0,0,1,7-4.9,35.9,35.9,0,0,1,12-1.61q7.91,0,11.67,1.25a15.1,15.1,0,0,1,6.29,3.88,20.46,20.46,0,0,1,4.18,7.13L29.43,30.31a5.68,5.68,0,0,0-2.08-3.24,8.28,8.28,0,0,0-4.83-1.38,6.68,6.68,0,0,0-4.18,1A3.18,3.18,0,0,0,17,29.31a2.72,2.72,0,0,0,1.66,2.54c1.11.58,3.53,1.09,7.25,1.56q8.44,1,12.57,2.76a13.92,13.92,0,0,1,6.31,5A12.78,12.78,0,0,1,47,48.41a14.53,14.53,0,0,1-2.31,7.72,15.73,15.73,0,0,1-7.28,6q-5,2.22-13.55,2.22-12.12,0-17.26-3.58A16.15,16.15,0,0,1,0,50.56Z" />
                        <path fill={primaryColor} d="M217.81,63.29l4-4V0H211.27l-6.58,6.58V21.93A16.29,16.29,0,0,0,199,17.79a18.06,18.06,0,0,0-7.18-1.38,17,17,0,0,0-13.39,6q-5.27,6-5.26,17.44,0,10.2,4.76,17.31t13.81,7.13a16.82,16.82,0,0,0,8.16-2,21.53,21.53,0,0,0,6.07-5.83v6.78ZM202.65,48.83a6.7,6.7,0,0,1-5.41,2.63,6.28,6.28,0,0,1-5.1-2.61q-2.05-2.61-2.06-8.22,0-6,2-8.55A6.06,6.06,0,0,1,197,29.53a7,7,0,0,1,5.58,2.66q2.2,2.66,2.21,8.05Q204.82,46.2,202.65,48.83Z" />
                        <path fill={primaryColor} d="M117.81,63.29l3.94-3.94V34.5q0-9.33-4-13.71t-11.1-4.38A18.23,18.23,0,0,0,98.55,18a25.66,25.66,0,0,0-7.28,6.1,14.17,14.17,0,0,0-5-5.78c-2.06-1.3-4.85-1.94-8.38-1.94a18.35,18.35,0,0,0-8.4,1.77,22.3,22.3,0,0,0-6.9,5.95V17.44h-9l-6.79,6.79V63.29h17V39.89q0-5.22,1.88-7.51a6,6,0,0,1,4.81-2.29,4.86,4.86,0,0,1,3.13,1.06,5.6,5.6,0,0,1,1.88,2.78A16.5,16.5,0,0,1,75.75,38V63.29h17V39.63q0-5,1.83-7.21a5.9,5.9,0,0,1,4.81-2.24,5,5,0,0,1,4.23,2.37,7.52,7.52,0,0,1,1.16,4.62V63.29Z" />
                        <path fill={primaryColor} d="M165.86,49.7H156c-1.07,4.46-6.84,4.31-8.35,4.31a7.91,7.91,0,0,1-6.41-3,11.62,11.62,0,0,1-2.2-6.3h26.7l7-7A27.62,27.62,0,0,0,170.08,28a19.78,19.78,0,0,0-8.36-8.64q-5.5-3-15-3-11.7,0-18.34,6.64t-6.64,17.4a25.31,25.31,0,0,0,3.31,13.15,20.6,20.6,0,0,0,8.35,8.16q5,2.57,13.82,2.57,10.11,0,15.53-3a22.48,22.48,0,0,0,7.61-7.12ZM141,30.14A8.1,8.1,0,0,1,153.08,29q2.14,2.2,2.64,7.3H139A12.43,12.43,0,0,1,141,30.14Z" />
                        <path fill={secondaryColor} d="M31.27,74.82a3,3,0,0,1,1-2.27,4.09,4.09,0,0,1,3-.94,6.31,6.31,0,0,1,2.9.71v1.55a5.14,5.14,0,0,0-2.71-.74c-1.71,0-2.56.56-2.56,1.69a1,1,0,0,0,.45.79,3.83,3.83,0,0,0,1.11.54c.44.13.92.31,1.44.52a13.79,13.79,0,0,1,1.44.68,3.06,3.06,0,0,1,1.11,1.07,3,3,0,0,1,.44,1.63,2.67,2.67,0,0,1-1.08,2.26,4.8,4.8,0,0,1-3,.82,7.43,7.43,0,0,1-3.34-.83V80.68a6.12,6.12,0,0,0,3.2.95c1.75,0,2.63-.53,2.64-1.58a1.64,1.64,0,0,0-.45-1.16,2.55,2.55,0,0,0-1.1-.69l-1.45-.48c-.53-.19-1-.37-1.45-.56a2.77,2.77,0,0,1-1.1-.89A2.4,2.4,0,0,1,31.27,74.82Z" />
                        <path fill={secondaryColor} d="M41.15,83V71.74H48.5v1.47H42.73v3.43H47.8V78.1H42.73v3.43H48.5V83Z" />
                        <path fill={secondaryColor} d="M50.84,83V71.74H55a4.55,4.55,0,0,1,3,.89A3.07,3.07,0,0,1,59,75.15a3.19,3.19,0,0,1-.72,2.2,3.61,3.61,0,0,1-1.8,1.12l3,4.53H57.66l-2.72-4.27H52.41V83Zm1.57-5.75h2.51a2.74,2.74,0,0,0,2-.57,2.06,2.06,0,0,0,.54-1.48c0-1.37-.87-2.06-2.61-2.06H52.41Z" />
                        <path fill={secondaryColor} d="M60.27,71.74h1.66L65.36,81l3.45-9.28h1.67L66.15,83H64.59Z" />
                        <path fill={secondaryColor} d="M72.33,83V71.74h1.56V83Z" />
                        <path fill={secondaryColor} d="M76.43,83V81.63l5.94-8.32H76.6V71.74h7.79v1.4l-5.9,8.31h5.9V83Z" />
                        <path fill={secondaryColor} d="M87.09,83V71.74h1.56V83Z" />
                        <path fill={secondaryColor} d="M97.73,83V71.74h1.69l3.69,4.86,3.72-4.86h1.69V83H107V74.12l-3.85,5-3.82-5V83Z" />
                        <path fill={secondaryColor} d="M111.33,83V71.74h7.35v1.47h-5.77v3.43H118V78.1h-5.07v3.43h5.77V83Z" />
                        <path fill={secondaryColor} d="M121,83V71.74h3.59a5.14,5.14,0,0,1,3.94,1.44,5.93,5.93,0,0,1,1.34,4.2,5.88,5.88,0,0,1-1.34,4.19A5.14,5.14,0,0,1,124.59,83Zm1.57-1.39h1.77a4,4,0,0,0,3-1,4.56,4.56,0,0,0,1-3.21,4.6,4.6,0,0,0-1-3.23,4,4,0,0,0-3-1h-1.77Z" />
                        <path fill={secondaryColor} d="M132.37,83V71.74h1.56V83Z" />
                        <path fill={secondaryColor} d="M136.69,79.79a9.39,9.39,0,0,1,0-4.86,5,5,0,0,1,.82-1.67,4.16,4.16,0,0,1,1.23-1,4.62,4.62,0,0,1,1.4-.52,7.46,7.46,0,0,1,1.46-.14,9.4,9.4,0,0,1,3.44.67v1.46a8,8,0,0,0-3.26-.71,3.63,3.63,0,0,0-2.89,1,5,5,0,0,0-.9,3.32,5,5,0,0,0,.9,3.31,3.6,3.6,0,0,0,2.89,1A7.82,7.82,0,0,0,145,81v1.45a9.21,9.21,0,0,1-3.44.68,8.35,8.35,0,0,1-1.46-.13,4.94,4.94,0,0,1-1.4-.53,4,4,0,0,1-1.23-1A4.91,4.91,0,0,1,136.69,79.79Z" />
                        <path fill={secondaryColor} d="M147.19,83V71.74h1.56V83Z" />
                        <path fill={secondaryColor} d="M157.84,83V71.74h3.59a5.14,5.14,0,0,1,3.94,1.44,5.93,5.93,0,0,1,1.34,4.2,5.92,5.92,0,0,1-1.34,4.19A5.15,5.15,0,0,1,161.43,83Zm1.57-1.39h1.77a4,4,0,0,0,3-1,4.56,4.56,0,0,0,1-3.21,4.6,4.6,0,0,0-1-3.23,4,4,0,0,0-3-1h-1.77Z" />
                        <path fill={secondaryColor} d="M169.21,83V71.74h1.55V83Z" />
                        <path fill={secondaryColor} d="M173.12,77.36a8.45,8.45,0,0,1,.32-2.42,4.74,4.74,0,0,1,.83-1.67,4.18,4.18,0,0,1,1.21-1,4.65,4.65,0,0,1,1.4-.53,7.52,7.52,0,0,1,1.47-.13,9.72,9.72,0,0,1,3.54.65V73.7A8.8,8.8,0,0,0,178.5,73a7.13,7.13,0,0,0-1.09.07,3.48,3.48,0,0,0-1,.35,2.58,2.58,0,0,0-.88.72,3.76,3.76,0,0,0-.6,1.27,6.8,6.8,0,0,0-.24,1.92,6.87,6.87,0,0,0,.24,1.93,3.82,3.82,0,0,0,.6,1.28,2.53,2.53,0,0,0,.89.72,3.49,3.49,0,0,0,1,.35,7.31,7.31,0,0,0,1.09.07,8.63,8.63,0,0,0,2.27-.32V78.22h-2.3V76.75h3.81v5.49a9,9,0,0,1-3.92.89h0a8.37,8.37,0,0,1-1.47-.13,4.94,4.94,0,0,1-1.4-.53,4.06,4.06,0,0,1-1.21-1,4.74,4.74,0,0,1-.83-1.67A8.47,8.47,0,0,1,173.12,77.36Z" />
                        <path fill={secondaryColor} d="M185,83V71.74h1.55V83Z" />
                        <path fill={secondaryColor} d="M188.44,73.21V71.74h9.16v1.47h-3.82V83h-1.55V73.21Z" />
                        <path fill={secondaryColor} d="M197.33,83l4.49-11.26h1.53L207.82,83h-1.69l-1.21-3.17h-4.69L199,83Zm3.46-4.63h3.57l-1.79-4.67Z" />
                        <path fill={secondaryColor} d="M209.68,83V71.74h1.56v9.79h5.6V83Z" />
                        <path fill={secondaryColor} d="M219,83V71.74h1.56V83Z" />
                        <path fill={tertiaryColor} d="M228.06,36.15h13.69l3.11,7.39,3-19.21,8.81,21.06,7-28.62,6.15,20.3h11.49l.25-.43a30.16,30.16,0,0,0,2.07-4.23A16.27,16.27,0,0,0,255,17.6a16.26,16.26,0,0,0-28.7,14.81A28.46,28.46,0,0,0,228.06,36.15Z" />
                        <path fill={tertiaryColor} d="M268.58,38.81,263.9,23.37l-6.75,27.57-8.48-20.28-3,19.18-5-12H229.08c.3.48.62,1,1,1.5A81,81,0,0,0,244.3,55L255,64.24,265.64,55a79.84,79.84,0,0,0,14.63-16.15Z" />
                    </g>
                </g>
            </svg>
        </div>
    );
}

function calculateColorSwatch(primaryColor: string): { secondaryColor: string, tertiaryColor: string } {
    const primaryRGB = hexToRgb(primaryColor);

    const { h, s, l } = rgbToHsl(primaryRGB.r, primaryRGB.g, primaryRGB.b);

    const h1 = (h * 360 + 2) % 360;
    const h2 = (h * 360 - 2 + 360) % 360;

    const s1 = Math.min(1, Math.max(0, s - 0.24));
    const s2 = Math.min(1, Math.max(0, s - 0.49));

    const l1 = Math.min(1, Math.max(0, l - 0.03));
    const l2 = Math.min(1, Math.max(0, l + 0.25));

    const secondaryColor = hslToHex(h1 / 360, s1, l1);
    const tertiaryColor = hslToHex(h2 / 360, s2, l2);

    return { secondaryColor, tertiaryColor };
}

function hexToRgb(hex: string): { r: number, g: number, b: number } {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}

function rgbToHsl(r: number, g: number, b: number): { h: number, s: number, l: number } {
    r /= 255; g /= 255; b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return { h, s, l };
}

function hslToHex(h: number, s: number, l: number): string {
    let r, g, b;
    if (s === 0) {
        r = g = b = l; // Grayscale
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    const toHex = (x: number) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export default Logo;