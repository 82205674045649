import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Button, Grid, Divider } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { getCosts, getUserCredits } from '../../api/credits';
import { getUser } from '../../api/users';
import { createPaymentSession } from '../../api/payments';
import { loadStripe } from '@stripe/stripe-js';

interface User {
    id_utente: number;
    nome: string;
    cognome: string;
    codice_fiscale: string;
    data_nascita: string;
    email: string;
}

interface Cost {
    id_costo: number;
    id_tipo_utente: number;
    id_servizio: number;
    quantita: number;
    costo: number;
    valuta: string;
    api_prezzo_stripe: string;
}

const stripePromise = loadStripe('pk_live_51JYfm3F8FajHpf3Qy3iu4rTSRpmEgkCXmwbjCHYzPJsDiFqxZwncQ22PIS353Z32pw4sfcbmZ1h02bWjbAjyUmOZ00N8wDT1hg'); // Sostituisci con la tua chiave pubblicabile

const ViewCredit: React.FC = () => {
    const [offers, setOffers] = useState<Cost[]>([]);
    const [userCredits, setUserCredits] = useState<number>(0);
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userIdFromStorage = localStorage.getItem('userId');
                if (!userIdFromStorage) {
                    throw new Error('User ID not found in storage');
                }

                const userId = parseInt(userIdFromStorage);
                const user = await getUser(userId);
                setUser(user);

                const credits = await getUserCredits(user.id_utente);
                setUserCredits(credits);
                console.log('User credits:', credits);
            } catch (error) {
                console.error('Error fetching user data or credits:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchOffers = async () => {
            try {
                const data = await getCosts();
                const filteredOffers = data.filter((offer: Cost) => offer.id_tipo_utente === 3);
                setOffers(filteredOffers);
            } catch (error) {
                console.error('Error fetching offers:', error);
            }
        };

        fetchUserData();
        fetchOffers();
    }, []);

    const handlePurchase = async (cost: Cost) => {
        try {
            const success_url = window.location.origin + '/payment-success';
            const cancel_url = window.location.origin + '/payment-cancel';

            // Log dei dati inviati
            console.log('Sending data to createPaymentSession:', {
                id_costo: cost.id_costo,
                success_url,
                cancel_url,
            });

            const paymentResponse = await createPaymentSession(cost.id_costo, success_url, cancel_url);

            const stripe = await stripePromise;
            const { error } = await stripe!.redirectToCheckout({
                sessionId: paymentResponse.id_sessione,
            });

            if (error) {
                console.error('Stripe checkout error:', error);
            }
        } catch (error) {
            console.error('Error processing payment:', error);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography variant="h6" color="white">Loading...</Typography>
            </Box>
        );
    }

    return (
        <Box padding="2rem">
            <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
                <ShoppingCartIcon style={{ fontSize: 50, marginRight: '10px', color: 'white' }} />
                <Typography variant="h4" color={'white'}>
                    Portafoglio
                </Typography>
            </Box>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                    <Card style={{ backgroundColor: '#f5f5f5' }}>
                        <CardContent>
                            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                                <MonetizationOnIcon style={{ fontSize: 50, color: 'orange' }} />
                            </Box>
                            <Typography variant="h4" color="textSecondary" align="center" gutterBottom>
                                {userCredits.toFixed(2)}
                            </Typography>
                            <Typography variant="h6" color="primary" align="center" gutterBottom>
                                Crediti residui
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {offers.map((offer) => (
                    <Grid item xs={12} sm={6} md={4} key={offer.id_costo}>
                        <Card style={{ backgroundColor: '#f5f5f5' }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {offer.quantita} Credits
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Con {offer.quantita} crediti puoi fare una Composizione Corporea!
                                </Typography>
                                <Divider sx={{ marginY: '1rem' }} />
                                <Typography variant="h6" color="textSecondary">
                                    Buy now at
                                </Typography>
                                <Typography variant="h4" color="primary" gutterBottom>
                                    €{(offer.costo / 100).toFixed(2)} {offer.valuta.toUpperCase()}
                                </Typography>
                                <Button variant="contained" color="primary" fullWidth onClick={() => handlePurchase(offer)}>
                                    Buy Now
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ViewCredit;
