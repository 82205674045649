import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Card, Grid } from '@mui/material';
import { getUser } from '../../api/users';
import { useUserProvider } from '../../providers/useUserProvider';
import styled from '@emotion/styled';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';

interface User {
    id_utente: number;
    nome: string;
    cognome: string;
    codice_fiscale: string;
    data_nascita: string;
    email: string;
}

const StyledCard = styled(Card)({
    padding: '2rem',
    borderRadius: '1rem',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    width: '100%',
    maxWidth: '600px', // Limita la larghezza massima della card
});

const Icon = styled('span')({
    verticalAlign: 'middle',
    marginRight: '0.5rem',
});

const Header = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
    fontSize: '1rem', // Smaller font size
});

const Content = styled(Typography)({
    fontSize: '0.875rem', // Smaller font size
});

const Title = styled(Typography)({
    fontSize: '1.5rem',
    color: 'white',
    marginBottom: '0.5rem',
    textAlign: 'left',
    width: '100%',
});

const Description = styled(Typography)({
    fontSize: '1rem',
    color: 'white',
    marginBottom: '1rem',
    textAlign: 'left',
    width: '100%',
});

const Container = styled(Box)({
    width: '100%',
    maxWidth: '600px',

});

const ViewProfile: React.FC = () => {
    const [userProfile, setUserProfile] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const { user } = useUserProvider();

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (user.userId !== null) {
                try {
                    const data = await getUser(user.userId);
                    setUserProfile(data);
                } catch (error) {
                    console.error('Error fetching user profile:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchUserProfile();
    }, [user]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box padding="2rem" display="flex" flexDirection="column" alignItems="flex-start">
            <Title variant="h4" gutterBottom>
                Profilo Utente
            </Title>
            <Description variant="body1" gutterBottom>
                Visualizza e gestisci le informazioni del tuo profilo.
            </Description>
            <Container>
                {userProfile ? (
                    <StyledCard>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Header variant="h6">
                                    <Icon>
                                        <AccountCircleOutlinedIcon />
                                    </Icon>
                                    <strong>Nome:</strong>
                                </Header>
                                <Content variant="body1">{userProfile.nome}</Content>
                            </Grid>
                            <Grid item xs={12}>
                                <Header variant="h6">
                                    <Icon>
                                        <AccountCircleOutlinedIcon />
                                    </Icon>
                                    <strong>Cognome:</strong>
                                </Header>
                                <Content variant="body1">{userProfile.cognome}</Content>
                            </Grid>
                            <Grid item xs={12}>
                                <Header variant="h6">
                                    <Icon>
                                        <CakeOutlinedIcon />
                                    </Icon>
                                    <strong>Data di Nascita:</strong>
                                </Header>
                                <Content variant="body1">{new Date(userProfile.data_nascita).toLocaleDateString()}</Content>
                            </Grid>
                            <Grid item xs={12}>
                                <Header variant="h6">
                                    <Icon>
                                        <FingerprintOutlinedIcon />
                                    </Icon>
                                    <strong>Codice Fiscale:</strong>
                                </Header>
                                <Content variant="body1">{userProfile.codice_fiscale}</Content>
                            </Grid>
                            <Grid item xs={12}>
                                <Header variant="h6">
                                    <Icon>
                                        <EmailOutlinedIcon />
                                    </Icon>
                                    <strong>Email:</strong>
                                </Header>
                                <Content variant="body1">{userProfile.email}</Content>
                            </Grid>
                        </Grid>
                    </StyledCard>
                ) : (
                    <Typography variant="body1">Nessun profilo utente trovato.</Typography>
                )}
            </Container>
        </Box>
    );
};

export default ViewProfile;
