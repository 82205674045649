import apiClient from './base';

interface PaymentResponse {
    message: string;
    id_pagamento: number;
    id_sessione: string;
}

export const createPaymentSession = async (id_costo: number, success_url: string, cancel_url: string): Promise<PaymentResponse> => {
    try {
        const response = await apiClient.post<PaymentResponse>('/tokens/buy.php', {
            id_costo,
            success_url,
            cancel_url
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        console.log('Received response from createPaymentSession:', response.data);

        return response.data;
    } catch (error) {
        console.error('Error creating payment session:', error);
        throw new Error('Error creating payment session');
    }
};
