import apiClient from './base';

interface Shape {
    id_shape: number;
    id_paziente: number;
    nome: string;
    cognome: string;
    timestamp_creazione: string;
    referto: number;
}

interface GetShapesResponse {
    shapes: Shape[];
}

export const getShapes = async (userId: number | null): Promise<Shape[]> => {
    try {
        const response = await apiClient.post<GetShapesResponse>('/shapes/fetch.php', {
            id_creatore: userId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`, // Utilizza il token salvato
            },
        });
        return response.data.shapes;
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Error fetching shapes');
        } else {
            throw new Error('Error fetching shapes');
        }
    }
};
