import apiClient from './base';

interface Transaction {
    id_movimento: number;
    id_utente: number;
    id_servizio: number;
    quantita: number;
    timestamp_movimento: string;
    causale: string;
}

interface GetTransactionsResponse {
    gettoni: Transaction[];
}

interface UserDetails {
    users: any;
    nome: string;
    cognome: string;
}

interface GetUserResponse {
    users: UserDetails[];
}

export const getTransactions = async (userId: number): Promise<Transaction[]> => {
    try {
        const response = await apiClient.post<GetTransactionsResponse>('/tokens/fetch.php', {
            id_utente: userId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        console.log('API response:', response.data);

        return response.data.gettoni;
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Error fetching transactions');
        } else {
            throw new Error('Error fetching transactions');
        }
    }
};

export const grantCredits = async (userId: number, quantity: number): Promise<void> => {
    try {
        await apiClient.post('/tokens/grant.php', {
            id_utente: userId,
            quantita: quantity,
            id_servizio: 1,  // Puoi cambiare questo ID servizio secondo necessità
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        console.log('Credits granted successfully');
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Error granting credits');
        } else {
            throw new Error('Error granting credits');
        }
    }
};

export const getUserDetails = async (userId: number): Promise<UserDetails> => {
    try {
        const response = await apiClient.post<UserDetails>('/users/fetch.php', {
            id_utente: userId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        console.log('API response:', response.data);

        const user = response.data.users[0];
        if (!user) {
            throw new Error('User not found');
        }

        return user;
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Error fetching user profile');
        } else {
            throw new Error('Error fetching user profile');
        }
    }
};