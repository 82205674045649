import { Outlet } from "react-router-dom";
import { UserProvider } from "../providers/useUserProvider";

function Main() {
    return (
        <UserProvider>
            <Outlet />
        </UserProvider>
    );
}

export default Main;