import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Card, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getTransactions, grantCredits, getUserDetails } from '../../api/patient';
import { getTransactionHistory } from '../../api/history';
import { useUserProvider } from '../../providers/useUserProvider';

interface Transaction {
    id_utente: number;
    id_servizio: number;
    quantita: number;
}

interface TransactionHistory {
    id_movimento: number;
    id_utente: number;
    id_servizio: number;
    quantita: number;
    timestamp_movimento: string;
}

interface UserDetails {
    nome: string;
    cognome: string;
}

const PatientDetail: React.FC = () => {
    const { id_utente } = useParams<{ id_utente: string }>();
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [transactionHistory, setTransactionHistory] = useState<TransactionHistory[]>([]);
    const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
    const [loading, setLoading] = useState(true);
    const [credits, setCredits] = useState('');
    const [selectedService, setSelectedService] = useState('1');
    const { user } = useUserProvider();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const transactionsData = await getTransactions(Number(id_utente));
                setTransactions(transactionsData);

                const historyData = await getTransactionHistory(Number(id_utente));
                // Ordina le transazioni in ordine decrescente
                historyData.sort((a, b) => new Date(b.timestamp_movimento).getTime() - new Date(a.timestamp_movimento).getTime());
                setTransactionHistory(historyData);

                const userData = await getUserDetails(Number(id_utente));
                setUserDetails(userData);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id_utente]);

    const handleGrantCredits = async () => {
        try {
            await grantCredits(Number(id_utente), Number(credits));
            setCredits('');
            // Ricarica le transazioni e la storia dopo aver conferito i crediti
            const transactionsData = await getTransactions(Number(id_utente));
            setTransactions(transactionsData);

            const historyData = await getTransactionHistory(Number(id_utente));
            // Ordina le transazioni in ordine decrescente
            historyData.sort((a, b) => new Date(b.timestamp_movimento).getTime() - new Date(a.timestamp_movimento).getTime());
            setTransactionHistory(historyData);
        } catch (error) {
            console.error('Error granting credits:', error);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box padding="2rem">
            <Typography variant="h4" gutterBottom>
                Dettaglio Crediti #{id_utente} {userDetails ? `${userDetails.nome} ${userDetails.cognome}` : ''}
            </Typography>
            <Box display="flex" justifyContent="space-between">
                <Box width="48%">
                    <Card sx={{ padding: "2rem", borderRadius: "1rem", backgroundColor: "white", marginBottom: '2rem' }}>
                        <Typography variant="h6" gutterBottom>Aggiungi Crediti</Typography>
                        <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                            <InputLabel>Servizio</InputLabel>
                            <Select
                                value={selectedService}
                                onChange={(e) => setSelectedService(e.target.value as string)}
                                label={"Servizio"}
                            >
                                <MenuItem value={1}>Composizione Corporea</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Quantità di Crediti"
                            variant="outlined"
                            fullWidth
                            value={credits}
                            onChange={(e) => setCredits(e.target.value)}
                            sx={{ marginBottom: '1rem' }}
                        />
                        <Button variant="contained" color="primary" onClick={handleGrantCredits}>
                            Conferma
                        </Button>
                    </Card>

                    <Card sx={{ padding: "2rem", borderRadius: "1rem", backgroundColor: "white" }}>
                        <Typography variant="h6" gutterBottom>Elenco Crediti</Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ID Servizio</TableCell>
                                        <TableCell align="center">Quantità Totale</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions.map((row, index) => (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell align="center">{row.id_servizio}</TableCell>
                                            <TableCell align="center">{row.quantita}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Box>

                <Box width="48%">
                    <Card sx={{ padding: "2rem", borderRadius: "1rem", backgroundColor: "white", height: '90%' }}>
                        <Typography variant="h6" gutterBottom>Storico Movimenti</Typography>
                        <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ID Servizio</TableCell>
                                        <TableCell align="center">Quantità</TableCell>
                                        <TableCell align="center">Data</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactionHistory.map((row) => (
                                        <TableRow hover tabIndex={-1} key={row.id_movimento}>
                                            <TableCell align="center">{row.id_servizio}</TableCell>
                                            <TableCell align="center" style={{ color: row.quantita < 0 ? 'red' : 'green' }}>
                                                {row.quantita}
                                            </TableCell>
                                            <TableCell align="center">{new Date(row.timestamp_movimento).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};

export default PatientDetail;
