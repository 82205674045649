import apiClient from './base';

interface Patient {
    id_utente: number;
    nome: string;
    cognome: string;
    codice_fiscale: string;
    data_nascita: string;
    email: string;
    telefono: string;
    id_creatore: number;
}

interface GetPatientsResponse {
    message: string;
    users: Patient[];
}

export const getPatients = async (userId: number | null): Promise<Patient[]> => {
    try {
        const response = await apiClient.post<GetPatientsResponse>('/users/fetch.php', {
            id_creatore: userId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`, // Utilizza il token salvato
            },
        });
        return response.data.users;
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Error fetching patients');
        } else {
            throw new Error('Error fetching patients');
        }
    }
};
