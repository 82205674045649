import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Definizione del tipo di stato
interface UserState {
    userId: number | null;
    userType: number | null;
}

// Inizializza il context con un valore di default undefined
const UserContext = createContext<{
    user: UserState;
    setUserId: (userId: number) => void;
    setUserType: (userType: number) => void;
    clearUser: () => void;
} | undefined>(undefined);

// Crea un provider
interface UserProviderProps {
    children: ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
    const [user, setUser] = useState<UserState>({
        userId: null,
        userType: null,
    });

    const navigate = useNavigate(); // Aggiunto per la navigazione
    const location = useLocation(); // Aggiunto per ottenere il percorso corrente
    const [isLoading, setIsLoading] = useState(true);

    const setUserId = (userId: number) => {
        setUser((prevUser) => ({ ...prevUser, userId }));
    };

    const setUserType = (userType: number) => {
        setUser((prevUser) => ({ ...prevUser, userType }));
    };

    const clearUser = () => {
        setUser({ userId: null, userType: null });
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        localStorage.removeItem('token');
    };

    useEffect(() => {
        const initializeUser = () => {
            const userId = localStorage.getItem('userId');
            const userType = localStorage.getItem('userType');
            const token = localStorage.getItem('token');

            const excludedPaths = ['/verify_account'];

            // Controlla se il percorso corrente è escluso
            if (!excludedPaths.includes(location.pathname)) {
                if (userId && userType && token) {
                    setUserId(Number(userId));
                    setUserType(Number(userType));
                } else {
                    clearUser();
                    navigate('/login/step1');
                }
            }
            setIsLoading(false);
        };

        initializeUser();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUserId, setUserType, clearUser }}>
            {!isLoading && children}
        </UserContext.Provider>
    );
}

// Hook personalizzato per utilizzare il context
export function useUserProvider() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserProvider must be used within a UserProvider');
    }
    return context;
}
