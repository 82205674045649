import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Card, Grid } from '@mui/material';
import { getShapeDetail } from '../../api/getShapeDetail';
import { useParams } from 'react-router-dom';

interface Patient {
    nome: string;
    cognome: string;
    codice_fiscale: string;
    data_nascita: string;
}

interface Shape {
    id_shape: number;
    id_paziente: number;
    timestamp_creazione: string;
    json_risposta: string;
}

interface ShapeDetailData {
    patient: Patient;
    latestShape: Shape;
    firstShape: Shape | null;
}

const ShapeDetail: React.FC = () => {
    const [data, setData] = useState<ShapeDetailData | null>(null);
    const [loading, setLoading] = useState(true);
    const { id_shape } = useParams<{ id_shape: string }>();

    useEffect(() => {
        const fetchShapeDetail = async () => {
            try {
                const responseData = await getShapeDetail(Number(id_shape));
                console.log('Fetched data:', responseData);
                setData(responseData);
            } catch (error) {
                console.error('Error fetching shape detail:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchShapeDetail();
    }, [id_shape]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!data || !data.latestShape) {
        return (
            <Box padding="2rem">
                <Typography variant="h6">No data available.</Typography>
            </Box>
        );
    }

    const { patient, latestShape, firstShape } = data;
    const { json_risposta } = latestShape;
    const shapeData = JSON.parse(json_risposta).data;
    const { all_calculations } = shapeData;

    return (
        <Box padding="2rem">
            <Card sx={{ padding: "2rem", borderRadius: "1rem", backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <Typography variant="h4" gutterBottom color="#212121">
                    Esame #{latestShape.id_shape} di {patient.nome} {patient.cognome}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h5" gutterBottom>Base</Typography>
                        <Typography variant="body1"><strong>Genere:</strong> {all_calculations.gender === 1 ? 'Maschile' : 'Femminile'}</Typography>
                        <Typography variant="body1"><strong>Età:</strong> {new Date().getFullYear() - new Date(patient.data_nascita).getFullYear()}</Typography>
                        <Typography variant="body1"><strong>Data di Nascita:</strong> {new Date(patient.data_nascita).toLocaleDateString()}</Typography>
                        <Typography variant="body1"><strong>Data Esame:</strong> {new Date(latestShape.timestamp_creazione).toLocaleDateString()}</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h5" gutterBottom>Misure</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Typography variant="body1"><strong>Altezza:</strong> {shapeData.htm} cm</Typography>
                                <Typography variant="body1"><strong>Peso:</strong> {shapeData.wtm} kg</Typography>
                                <Typography variant="body1"><strong>Rapporto vita/altezza:</strong> {shapeData.waist_to_height}</Typography>
                                <Typography variant="body1"><strong>Diametro Torace:</strong> {shapeData.chest_cm} cm</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body1"><strong>Diametro Addome:</strong> {shapeData.belly_cm} cm</Typography>
                                <Typography variant="body1"><strong>Diametro Fianchi:</strong> {shapeData.hip_cm} cm</Typography>
                                <Typography variant="body1"><strong>Diametro Coscia:</strong> {shapeData.thigh_cm} cm</Typography>
                                <Typography variant="body1"><strong>Diametro Polpaccio:</strong> {shapeData.calf_cm} cm</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h5" gutterBottom>Silhouette</Typography>
                        {shapeData.full_path && (
                            <Box display="flex" justifyContent="center">
                                <img src={shapeData.full_path} alt="Shape" style={{ maxWidth: '100%', height: 'auto' }} />
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h5" gutterBottom>Stime</Typography>
                        <Typography variant="body1"><strong>Massa Grassa (FM):</strong> {all_calculations.fat_mass} kg</Typography>
                        <Typography variant="body1"><strong>Massa Magra (FFM):</strong> {all_calculations.free_fat_mass} kg</Typography>
                        <Typography variant="body1"><strong>Massa Grassa Addominale:</strong> {all_calculations.abdominal_fat_mass} kg</Typography>
                        <Typography variant="body1"><strong>FM %:</strong> {all_calculations.fat_x_weight} %</Typography>
                        <Typography variant="body1"><strong>FFM %:</strong> {all_calculations.free_fat_mass_percent} %</Typography>
                        <Typography variant="body1"><strong>Indice di Massa Corporea (BMI):</strong> {all_calculations.bmi}</Typography>
                        <Typography variant="body1"><strong>Metabolismo Basale (BMR):</strong> {all_calculations.basal_metabolic_rate} kcal</Typography>
                        <Typography variant="body1"><strong>Acqua Totale (TBW):</strong> {all_calculations.body_water} kg</Typography>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default ShapeDetail;
