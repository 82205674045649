import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Card, Button, TextField, IconButton } from '@mui/material';
import SortableTable, { HeadCell } from '../../components/SortableTable';
import { getShapes } from '../../api/getShapes';
import { useUserProvider } from '../../providers/useUserProvider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface Shape {
    id_shape: number;
    nome: string;
    cognome: string;
    timestamp_creazione: string;
    referto: number;
}

const headCells: HeadCell<Shape>[] = [
    { id: 'id_shape', numeric: true, disablePadding: false, label: 'ID Shape' },
    { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
    { id: 'timestamp_creazione', numeric: false, disablePadding: false, label: 'Data Creazione' },
    { id: 'referto', numeric: false, disablePadding: false, label: 'Referto' },
];

const ViewShape: React.FC = () => {
    const [shapes, setShapes] = useState<Shape[]>([]);
    const [filteredShapes, setFilteredShapes] = useState<Shape[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const { user } = useUserProvider();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchShapes = async () => {
            if (user.userId !== null) {
                try {
                    const data = await getShapes(user.userType === 1 ? null : user.userId);
                    setShapes(data);
                    setFilteredShapes(data);
                } catch (error) {
                    console.error('Error fetching shapes:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchShapes();
    }, [user]);

    useEffect(() => {
        const filtered = shapes.filter(shape =>
            shape.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            shape.cognome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            shape.id_shape.toString().includes(searchTerm)
        );
        setFilteredShapes(filtered);
    }, [searchTerm, shapes]);

    const renderRow = (row: Shape) => (
        <TableRow hover tabIndex={-1} key={row.id_shape}>
            <TableCell sx={{ textAlign: 'left', paddingLeft: '60px' }}>{row.id_shape}</TableCell>
            <TableCell>{row.nome}</TableCell>
            <TableCell>{row.cognome}</TableCell>
            <TableCell>{new Date(row.timestamp_creazione).toLocaleDateString()}</TableCell>
            <TableCell>
                <IconButton
                    color="primary"
                    onClick={() => navigate(`/shape/${row.id_shape}`)}
                >
                    <VisibilityIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box padding="2rem">
            <Card sx={{ padding: '1rem', borderRadius: '1rem' }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ marginBottom: '1rem' }}
                />
                <SortableTable<Shape>
                    rows={filteredShapes}
                    headCells={headCells}
                    renderRow={renderRow}
                />
            </Card>
        </Box>
    );
};

export default ViewShape;
