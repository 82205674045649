// services/verifyAccount.ts
import apiClient from './base';

export const verifyAccount = async (token: string) => {
    try {
        const response = await apiClient.post('/verifica/verify_account.php', { token });

        console.log('Received response from verifyAccount:', response.data);

        return response.data;
    } catch (error) {
        console.error('Error verifying account:', error);
        throw new Error('Error verifying account');
    }
};
