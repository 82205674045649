import { PaletteColor, PaletteColorOptions, TypeText, createTheme, styled } from "@mui/material"

declare module "@mui/material/styles" {
    interface Palette {
        primarySelected: PaletteColor;
        primaryDark: PaletteColor;
        secondaryDark: PaletteColor;
        primarySelectedDark: PaletteColor;
        textDark: TypeText;
        orange?: {
            main: string;
        };
    }
    interface PaletteOptions {
        primarySelected?: PaletteColorOptions;
        primaryDark?: PaletteColorOptions;
        secondaryDark?: PaletteColorOptions;
        primarySelectedDark?: PaletteColorOptions;
        textDark?: TypeText;
        orange?: {
            main?: string;
        };
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: "#009bbc",
            contrastText: "#ffffff"
        },
        primarySelected: {
            main: "#009bbc1a",
        },
        primaryDark: {
            main: "#ffffff",
            contrastText: "#212121"
        },
        secondary: {
            main: "#f44336",
            contrastText: "#212121"
        },
        secondaryDark: {
            main: "#e0e0e0",
        },
        primarySelectedDark: {
            main: "#009bbc1a",
        },
        success: {
            main: "#28c76f"
        },
        error: {
            main: "#ff5252"
        },
        info: {
            main: "#2196f3"
        },
        warning: {
            main: "#ff9800",
        },
        background: {
            default: "#009bbc",
        },
        text: {
            primary: "#212121",
            secondary: "#757575",
            disabled: "#9e9e9e",
        },
        textDark: {
            primary: "#ffffff",
            secondary: "#e0e0e0",
            disabled: "#9e9e9e",
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "uppercase",
                    borderRadius: "1.5rem",
                    height: "3rem",
                    padding: "0 2rem",
                },
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: "-0.2rem",
                },
                shrink: {
                    top: "0rem",
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: "1rem 2rem",
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "0.5rem",
                    height: "3rem",
                },
                multiline: {
                    height: "auto",
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: "2.5rem",
                    borderRadius: "2rem",
                    boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.08)"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: "1rem 2rem",
                }
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "1rem",
                    padding: "1rem",
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: "1rem",
                    boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.24)"
                }
            }
        },
    }
});
