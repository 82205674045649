import axios, { AxiosInstance } from 'axios';

const apiClient: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL ?? 'http://54.37.74.47:3111/api', // Assicurati che questo URL sia corretto e utilizza HTTPS
    headers: {
        'Content-Type': 'application/json',
    },
});

export default apiClient;
