// routes.tsx
import { createBrowserRouter } from "react-router-dom";
import App from "../pages/app/App";
import Login from "../pages/auth/login/Login";
import ViewCredit from "../pages/dashboard/view_credit";
import ViewProfile from "../pages/dashboard/view_profile";
import ViewShape from "../pages/dashboard/view_shape";
import ShapeDetail from "../pages/dashboard/shape_detail";
import PatientDetail from "../pages/dashboard/patient_detail";
import Logout from "../pages/auth/logout/Logout";
import Error from "../pages/error/Error";
import Main from "../pages/Main";
import ViewPazienti from "../pages/dashboard/view_pazienti";
import PaymentSuccess from "../components/PaymentSuccess";
import PaymentCancel from "../components/PaymentCancel";
import VerifyAccount from "../pages/dashboard/verify_account";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        errorElement: <Error />,
        children: [
            {
                path: "/",
                element: <App />,
                children: [
                    {
                        path: "view_pazienti",
                        element: <ViewPazienti />,
                    },
                    {
                        path: "view_shape",
                        element: <ViewShape />,
                    },
                    {
                        path: "shape/:id_shape",
                        element: <ShapeDetail />,
                    },
                    {
                        path: "view_profile",
                        element: <ViewProfile />,
                    },
                    {
                        path: "view_credit",
                        element: <ViewCredit />,
                    },
                    {
                        path: "patient/:id_utente",
                        element: <PatientDetail />,
                    },
                    {
                        path: "payment-success",
                        element: <PaymentSuccess />,
                    },
                    {
                        path: "payment-cancel",
                        element: <PaymentCancel />,
                    },
                ]
            },
            {
                path: "login",
                children: [
                    {
                        path: "step1",
                        element: <Login />
                    },
                ]
            },
            {
                path: "logout",
                element: <Logout />
            },
            {
                path: "error",
                element: <Error />
            },
            {
                path: "verify_account",
                element: <VerifyAccount />, // Route per la verifica dell'account
            },
            {
                path: "*",
                element: <Error />
            },
        ]
    },
]);

export default router;
