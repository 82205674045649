interface MaterialIconProps {
    icon: string;
    size?: string;
    color?: string;
    iconStyle?: 'outlined' | 'rounded' | 'sharp'
}

const MaterialIcon: React.FC<MaterialIconProps> = ({ icon, size, color, iconStyle = "rounded" }) => {
    return (
        <div
            className={`material-symbols-${iconStyle}`}
            style={{
                fontSize: size,
                color: color,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                height: "fit-content"
            }}>
            {icon}
        </div>
    );
}

export default MaterialIcon;