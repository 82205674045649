import apiClient from './base';

interface TransactionHistory {
    id_movimento: number;
    id_utente: number;
    id_servizio: number;
    quantita: number;
    timestamp_movimento: string;
}

interface GetTransactionHistoryResponse {
    storico: TransactionHistory[];
}

export const getTransactionHistory = async (userId: number): Promise<TransactionHistory[]> => {
    try {
        const response = await apiClient.post<GetTransactionHistoryResponse>('/tokens/history.php', {
            id_utente: userId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        console.log('API response:', response.data);

        return response.data.storico;
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Error fetching transaction history');
        } else {
            throw new Error('Error fetching transaction history');
        }
    }
};
