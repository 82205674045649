import { Box, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the loading state type
interface LoadingState {
    counter: number;
}

// Initialize the context with a default value of undefined
const LoadingContext = createContext<{
    loading: LoadingState;
    openLoadingDialog: () => void;
    closeLoadingDialog: () => void;
} | undefined>(undefined);

// Create a provider
interface LoadingProviderProps {
    children: ReactNode;
}

export function SwallowLoadingProvider({ children }: LoadingProviderProps) {
    const [loading, setLoading] = useState<LoadingState>({
        counter: 0,
    });

    const openLoadingDialog = () => {
        setLoading((prevLoading) => ({ counter: prevLoading.counter + 1 }));
    };

    const closeLoadingDialog = () => {
        setLoading((prevLoading) => ({ counter: Math.max(prevLoading.counter - 1, 0) }));
    };

    return (
        <LoadingContext.Provider value={{ loading, openLoadingDialog, closeLoadingDialog }}>
            {children}
            <Dialog open={loading.counter > 0} fullWidth maxWidth="xs">
                <DialogContent>
                    <DialogTitle textAlign="center">Caricamento in corso...</DialogTitle>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            </Dialog>
        </LoadingContext.Provider>
    );
}

// Custom hook to use the context
export function useSwallowLoading() {
    const context = useContext(LoadingContext);
    if (context === undefined) {
        throw new Error('useSwallowLoading must be used within a SwallowLoadingProvider');
    }
    return context;
}