import { useNavigate } from "react-router-dom";
import { useUserProvider } from "../../../providers/useUserProvider";
import { useEffect } from "react";

const Logout: React.FC = () => {
    const { clearUser } = useUserProvider();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("token");
        clearUser();
        navigate("/login/step1");
    }

    useEffect(() => {
        handleLogout();
    });

    return null;
}

export default Logout;