import React from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../components/MaterialIcon";
import { useLocation, useNavigate } from "react-router-dom";

const Error: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as { errorStatus?: number } | undefined;

    const handleBack = () => {
        navigate(-1);
    }

    let statusCode = state?.errorStatus ?? 404;
    let message = "";
    let description = "";

    switch (statusCode) {
        case 401:
            message = "Non autorizzato";
            description = "Non hai i permessi necessari per visualizzare questa pagina";
            break;
        case 403:
            message = "Vietato";
            description = "Non hai i permessi necessari per visualizzare questa pagina";
            break;
        case 404:
            message = "Pagina non trovata";
            description = "La pagina che stai cercando non esiste";
            break;
        default:
            message = "Errore";
            description = "Si è verificato un errore";
            break;
    }

    return (
        <Box
            width="100svw"
            height="100svh"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: theme.palette.background.default,
            }}>
            <MaterialIcon icon="error" color="error" size="8rem" />
            <Typography variant="h2" color="error" textAlign="center" gutterBottom sx={{ marginTop: "2rem" }}>{statusCode}</Typography>
            <Typography variant="h4" color="textPrimary" textAlign="center" gutterBottom>{message}</Typography>
            <Typography variant="body1" color="textSecondary" textAlign="center" sx={{ marginBottom: "2rem" }}>{description}</Typography>
            <Button
                variant="contained"
                sx={{ borderRadius: "2rem" }}
                style={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                }}
                onClick={handleBack}>
                Torna indietro
            </Button>
        </Box>
    );
}

export default Error;
