import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { verifyAccount } from '../../api/verifyAccount';

const VerifyAccount: React.FC = () => {
    const [message, setMessage] = useState<string>('Stiamo verificando il tuo account...');
    const location = useLocation();

    useEffect(() => {
        console.log('Verifying account')
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            verifyAccount(token)
                .then((data: any) => {
                    if (data.success) {
                        setMessage("Il tuo account è stato verificato con successo, controlla la tua mail per ulteriore informazioni relative all'utilizzo dell'app");
                    } else {
                        setMessage('Verifica fallita. Si prega di riprovare o contattare il supporto.');
                    }
                })
                .catch(error => {
                    setMessage('Si è verificato un errore. Si prega di riprovare più tardi.');
                    console.error('Errore di verifica:', error);
                });
        } else {
            setMessage('Link di verifica non valido.');
        }
    }, [location]);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" flexDirection="column">
            <Typography variant="h6" color="white" gutterBottom>
                {message}
            </Typography>
            {message === 'Verifying your account...' && <CircularProgress color="inherit" />}
        </Box>
    );
};

export default VerifyAccount;
