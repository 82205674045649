import apiClient from './base';

interface LoginData {
    codice_fiscale: string;
    password: string;
    devMode: boolean;
}

interface LoginResponse {
    message: string;
    jwt: string;
    userId: number;
    userType: number;
}

export const login = async (data: LoginData): Promise<LoginResponse> => {
    try {
        const response = await apiClient.post<LoginResponse>('/auth/login.php', data);
        // Save the JWT token and user ID in localStorage
        localStorage.setItem('token', response.data.jwt);
        localStorage.setItem('userId', response.data.userId.toString());
        return response.data;
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Login failed');
        } else {
            throw new Error('Login failed');
        }
    }
};
