import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Card, TextField } from '@mui/material';
import SortableTable, { HeadCell } from '../../components/SortableTable';
import { getPatients } from '../../api/patients';
import { useUserProvider } from '../../providers/useUserProvider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';

interface Patient {
    id_utente: number;
    nome: string;
    cognome: string;
    codice_fiscale: string;
    data_nascita: string;
    email: string;
    telefono: string;
    id_creatore: number;
}

const headCells: HeadCell<Patient>[] = [
    { id: 'id_utente', numeric: true, disablePadding: false, label: 'ID Utente' },
    { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
    { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice Fiscale' },
    { id: 'data_nascita', numeric: false, disablePadding: false, label: 'Data di Nascita' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
];

const ViewPazienti: React.FC = () => {
    const [patients, setPatients] = useState<Patient[]>([]);
    const [filteredPatients, setFilteredPatients] = useState<Patient[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const { user } = useUserProvider();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPatients = async () => {
            if (user.userId !== null) {
                try {
                    const data = await getPatients(user.userType === 1 ? null : user.userId);
                    setPatients(data);
                    setFilteredPatients(data);
                } catch (error) {
                    console.error('Error fetching patients:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchPatients();
    }, [user]);

    useEffect(() => {
        const filtered = patients.filter(patient =>
            patient.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            patient.cognome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            patient.id_utente.toString().includes(searchTerm)
        );
        setFilteredPatients(filtered);
    }, [searchTerm, patients]);

    const handleRowClick = (patientId: number) => {
        if (user.userType === 1) {
            navigate(`/patient/${patientId}`);
        }
    };

    const renderRow = (row: Patient) => (
        <TableRow hover tabIndex={-1} key={row.id_utente} onClick={() => handleRowClick(row.id_utente)}>
            <TableCell sx={{ textAlign: 'left', paddingLeft: '60px' }}>{row.id_utente}</TableCell>
            <TableCell>{row.nome}</TableCell>
            <TableCell>{row.cognome}</TableCell>
            <TableCell>{row.codice_fiscale}</TableCell>
            <TableCell>{new Date(row.data_nascita).toLocaleDateString()}</TableCell>
            <TableCell>{row.email}</TableCell>
        </TableRow>
    );

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box padding="2rem">
            <Card sx={{ padding: '1rem', borderRadius: '1rem' }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ marginBottom: '1rem' }}
                />
                <SortableTable<Patient>
                    rows={filteredPatients}
                    headCells={headCells}
                    renderRow={renderRow}
                />
            </Card>
        </Box>
    );
};

export default ViewPazienti;
