import apiClient from './base';

interface User {
    id_utente: number;
    nome: string;
    cognome: string;
    codice_fiscale: string;
    data_nascita: string;
    email: string;
}

interface GetUserResponse {
    users: User[];
}

export const getUser = async (userId: number): Promise<User> => {
    try {
        const response = await apiClient.post<GetUserResponse>('/users/fetch.php', {
            id_utente: userId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        console.log('API response:', response.data);

        const user = response.data.users[0];
        if (!user) {
            throw new Error('User not found');
        }

        return user;
    } catch (error: any) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Error fetching user profile');
        } else {
            throw new Error('Error fetching user profile');
        }
    }
};
