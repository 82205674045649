import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentCancel: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div>
            <h1>Pagamento annullato</h1>
            <p>Il tuo pagamento è stato annullato. Riprova.</p>
            <button onClick={() => navigate('/')}>Torna alla Home</button>
        </div>
    );
};

export default PaymentCancel;