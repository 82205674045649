import { Box, IconButton, LinearProgress, Typography, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import MaterialIcon from "./MaterialIcon";

const TOAST_DURATION = 3000;

interface ToastProps extends CustomContentProps {
    message: string;
    type: 'success' | 'warning' | 'error' | 'info';
}

const Toast = React.forwardRef<HTMLDivElement, ToastProps>(({ message, type, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const theme = useTheme();
    const [progress, setProgress] = React.useState(100);

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    const mainColor = type === "success" ? theme.palette.success.main : type === "warning" ? theme.palette.warning.main : type === "error" ? theme.palette.error.main : theme.palette.info.main;
    const mainIcon = type === "success" ? "check_circle" : type === "warning" ? "warning" : type === "error" ? "warning" : "info";

    React.useEffect(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress <= 0) {
                    clearInterval(interval);
                    handleDismiss();
                    return 0;
                }

                return oldProgress - 100 / (TOAST_DURATION / 100);
            });
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [handleDismiss]);

    return (
        <SnackbarContent ref={ref}>
            <Box
                className={`Toast Toast-${type}`}
                position="relative"
                padding=".5rem"
                borderRadius=".5rem"
                display="flex"
                boxShadow="0 0.25rem 0.5rem rgba(0, 0, 0, 0.08)"
                bgcolor={mainColor}
                maxWidth="100svw"
                minWidth="24rem"
                gap="1rem"
                alignItems="center"
                overflow="hidden"
            >
                <Box
                    sx={{
                        width: "3rem",
                        height: "3rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    flex="0 0 auto">
                    <MaterialIcon
                        icon={mainIcon}
                        color={theme.palette.textDark.primary} />
                </Box>
                <Typography
                    variant="subtitle1"
                    color={theme.palette.textDark.primary}
                    sx={{ flex: "1" }}>
                    {message}
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        aspectRatio: "1",
                    }}>
                    <IconButton
                        sx={{
                            marginLeft: "auto",
                            flex: "0 0 auto",
                            width: "3rem",
                            height: "3rem",
                        }}
                        onClick={handleDismiss}
                    >
                        <MaterialIcon icon="close" color={theme.palette.textDark.primary} />
                    </IconButton>
                </Box>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    color={type === "success" ? "success" : type === "warning" ? "warning" : type === "error" ? "error" : "info"}
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }} />
            </Box>
        </SnackbarContent>
    );
});

export const ToastSuccess = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
    return <Toast {...props} type="success" ref={ref} />;
});

export const ToastWarning = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
    return <Toast {...props} type="warning" ref={ref} />;
});

export const ToastError = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
    return <Toast {...props} type="error" ref={ref} />;
});

export const ToastInfo = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
    return <Toast {...props} type="info" ref={ref} />;
});