import apiClient from './base';

interface Cost {
    id_costo: number;
    id_tipo_utente: number;
    id_servizio: number;
    quantita: number;
    costo: number;
    valuta: string;
    api_prezzo_stripe: string;
}

export const getCosts = async (): Promise<Cost[]> => {
    try {
        const response = await apiClient.post<{ data: Cost[] }>('/prices/fetch.php', {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error fetching costs:', error);
        throw new Error('Error fetching costs');
    }
};



export const getUserCredits = async (userId: number): Promise<number> => {
    try {
        const response = await apiClient.post<{ gettoni: { quantita: number }[] }>('/tokens/fetch.php', {
            id_utente: userId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        console.log('API response:', response.data);
        const gettoni = response.data.gettoni;
        return gettoni.length > 0 ? gettoni[0].quantita : 0;
    } catch (error) {
        console.error('Error fetching user credits:', error);
        throw new Error('Error fetching user credits');
    }
};